import { Card } from "@mui/material";
import React from "react";
import "./Styles/PropertyFloorPlanCard.css";
import PropTypes from "prop-types";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import useWindowDimensions from "../../../../Hooks/UseWindowDimentions";
import tenackLogo from "../../../../Files/Images/tenacklogo.png"

function PropertyFloorPlanCard(props) {
  const { url } = props;

  const window = useWindowDimensions();

  const width = () => {
    if (window.width > 800) {
      return 500;
    } else {
      const calc = window.width * 0.98 - 20;
      return calc;
    }
  };

  return (
    <> 
      <Card className="PropertyFloorPlanCard_card">
        <div className="PropertyFloorPlanCard_title">Floor Plan</div>
        <div className="PropertyFloorPlanCard_master">
          <div className="PropertyFloorPlanCard_sub">
            {url === "" ? (
              <img src={""} alt="Property" />
            ) : (
              <Document
                noData={(
                <div style={{
                  display:'flex', 
                  flexDirection:'column', 
                  alignItems:'center'
                }}>
                <img style={{width:'80%', marginBottom:'20px'}} src={tenackLogo}alt='no pdf yo' />
                No Floor Plan Available.
                 </div>


              )
              }
                file={url}
              >
                <Page width={width()} pageNumber={1} style={{ width: "90%" }} />
              </Document>
            )}
          </div>
        </div>
      </Card>
    </>
  );
}

PropertyFloorPlanCard.propTypes = {
  url: PropTypes.string,
};

export default PropertyFloorPlanCard;
