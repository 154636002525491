import { Alert, Snackbar } from "@mui/material";
import PropTypes from 'prop-types';
import React from "react";

function DualSnackBar(props) {
  const errorMessage = props.errorMessage;
  const successMessage = props.successMessage;


  const [state, setState] = React.useState({
    errorMessage: "",
    errorOpen: false,
    successOpen: false,
    successMessage: "",
  });

  React.useEffect(() => {
      if (errorMessage === '' && successMessage.length > 2) {
          // handle adding success Message
          setState((state) => ({
            ...state,
            errorMessage: "",
            errorOpen: false,
            successOpen: true,
            successMessage: successMessage,
          }));

      } else if (successMessage === '' && errorMessage.length > 2) {
          //handle adding error message
          setState((state) => ({
            ...state,
            errorMessage: errorMessage,
            errorOpen: true,
            successOpen: false,
            successMessage: "",
          }));

      } else {
          // set both values to empty strings.
          setState((state) => ({
            ...state,
            errorMessage: "",
            errorOpen: false,
            successOpen: false,
            successMessage: "",
          }));
      }
  }, [props]);

  /* handle snackbar jazz */
  function handleErrorClose() {
    setState((state) => ({
      ...state,
      errorMessage: "",
      errorOpen: false,
      successOpen: false,
      successMessage: "",
    }));
  }
  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{ maxWidth: "30%" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{ maxWidth: "30%" }}
      >
        <Alert onClose={handleErrorClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

DualSnackBar.propTypes = {
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
};

export default DualSnackBar;
