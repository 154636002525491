import { CircularProgress } from "@mui/material";
import React from "react";
import "./Styles/CreateRequestPage.css";
import { useAuth } from "../../Contexts/AuthContext.js";
import { db } from "../../Firebase";
import firebase from "firebase";
import { useNavigate, useParams } from "react-router-dom";

function CreateRequestPage() {
  // hooks
  const { currentUser } = useAuth();
  const { property_id } = useParams();
  const navigate = useNavigate();

  // useEffect function
  React.useEffect(() => {
    // check if theres a in progess maintenace request at this property
    db.collection("tenant")
      .doc(currentUser.uid)
      .collection("accessible_properties")
      .doc(property_id)
      .get()
      .then((r) => {
        const data = r.data();
        console.log(data)
        if (
          r.exists &&(
          typeof data.in_progress_request === "undefined" ||
          data.in_progress_request === "")
        ) {
          //  create new maintenace request doc and set it as the in progress request
          db.collection("tenant")
            .doc(currentUser.uid)
            .collection("accessible_properties")
            .doc(property_id)
            .collection("maintenance_requests")
            .add({
              created_at: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((r) => {
              // then navigate to edit page
              navigate(
                `/property/${property_id}/maintenance_request_edit/${r.id}`
              );
            });
        } else {
          // if there is navigate to the form page
          if (
            window.confirm(`You've already got a request in progress would you like to finish this request?`)
          ) {
            navigate(
              `/property/${property_id}/maintenance_request_edit/${data.in_progress_request}`
            );
          } else {
            db.collection("tenant")
              .doc(currentUser.uid)
              .collection("accessible_properties")
              .doc(property_id)
              .collection("maintenance_requests")
              .add({
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
              })
              .then((r) => {
                // then navigate to edit page
                navigate(
                  `/property/${property_id}/maintenance_request_edit/${r.id}`
                );
              });
          }
        }
      });
  }, []);

  return (
    <>
      <div className="CreateRequestPage_master">
        <div className="CreateRequestPage_sub">
          <div>
            <CircularProgress
              size={60}
              style={{ color: "rgb(18, 100, 100)" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateRequestPage;
