import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../Contexts/AuthContext';
import { db } from '../../../Firebase';
import './Styles/RequestUrgency.css'

function RequestUrgency() {
  const { property_id, request_id } = useParams();
  const {currentUser} = useAuth();
  
  const [state, setState] = React.useState({
    urgency: '', 
  })

  function onBlur () {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .update({
      urgency: state.urgency
    }).catch((r) => {
      console.log('error', r)
    })

  } 

  React.useEffect(() => {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .get()
    .then((r) => {
      setState((state) => ({
        ...state, 
        urgency: r.data().urgency ? r.data().urgency : ''
      }))
    }).catch((r) => {
      console.log('error', r)
    })


  }, [])




const urgencyLevels = [
    'Low', 
    'Medium', 
    'High', 
]
  return (<>
  <div className='RequestUrgency_title'> 
      How Urgent is the work?
  </div>
  <div className='RequestUrgency_subtitle'> 
      keeping in mind things like heating and cooling and hot water are urgent repairs
  </div>
  <div className='RequestUrgency_select'> 
      <Select
      fullWidth
      style={{color:'rgb(18, 100, 100)'}}
      value={state.urgency}
      onChange={((e) => {
        setState((state) => ({
          ...state, 
          urgency: e.target.value
        }))
      })}
      onBlur={onBlur}
      >
    {urgencyLevels && urgencyLevels.map((item) => (
          <MenuItem key={item} value={item}>{item}</MenuItem>
    ))}
    </Select>
  </div>
  
  
  
  </>
  )
}

export default RequestUrgency