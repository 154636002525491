import { TextField } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../Contexts/AuthContext';
import { db } from '../../../Firebase'
import './Styles/RequestTitle.css'

function RequestTitle() {
  const { property_id, request_id } = useParams();
  const {currentUser} = useAuth();
  
  const [state, setState] = React.useState({
    title: '', 
  })

  function onBlur () {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .update({
      title: state.title
    }).catch((r) => {
      console.log('error', r)
    })

  } 

  React.useEffect(() => {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .get()
    .then((r) => {
      setState((state) => ({
        ...state, 
        title: r.data().title ? r.data().title : ''
      }))
    }).catch((r) => {
      console.log('error', r)
    })


  }, [])
  

  return (<>
  <div className='RequestTitle_title'> 
      In as few words as possible what requires attention? 
  </div>
  <div className='RequestTitle_textfield'> 
      <TextField
      placeholder='Leaking Bathroom Tap...'
      fullWidth
      style={{color:'rgb(18, 100, 100)'}}
      value={state.title}
      onChange={((e) => {
        setState((state) => ({
          ...state, 
          title: e.target.value
        }))
      })}
      onBlur={onBlur}

      />
  </div>
  
  
  
  
  </>
  )
}

export default RequestTitle