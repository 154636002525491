import { Card } from '@mui/material'
import React from 'react'
import "./Styles/PropertyImageCard.css"
import PropTypes from 'prop-types';


function PropertyImageCard(props) {
  const {url} = props;
  return (<>
  <Card className='PropertyImageCard_card'> 
    <div className='PropertyImageCard_sub'> 
      <img  src={url ? url : ''} className='PropertyImageCard_img' alt='Property'/>
    </div>
  </Card>
  </>
  )
}

PropertyImageCard.propTypes = {
  url: PropTypes.string,
};

export default PropertyImageCard