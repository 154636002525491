export const tradesmanTypes = [
    'Electrician', 
    'Plumber', 
    'Carpenter', 
    'Builder', 
    'Landscaper', 
    'Arborist', 
    'Painter', 
    'Aircon Specialist', 
    'Glazier', 
    'Locksmith', 
    'Handyman',
    'Concreter', 
    'Other', 
]