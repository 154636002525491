import React from "react";
import logo from "../../../Files/Images/tenacklogo.png";
import "./Styles/StartRequestScreen.css";

function StartRequestScreen() {
  return (
    <>
      <div className="StartRequestScreen_logo_div">
        <img
          src={logo}
          alt="Tenack Logo"
          className="StartRequestScreen_logo_img"
        />
      </div>
      <div className="StartRequestScreen_subtitle">New Maintenace Request</div>
      <div className="StartRequestScreen_text_parent">
        <div className="StartRequestScreen_text">
          Filing maintenace requests through tenack is quick and easy, simply
          complete this form answering the questions as accurately as possible,
          the more infomation and images provided the quicker the request can be
          processed.
        </div>
      </div>
    </>
  );
}

export default StartRequestScreen;
