import React from "react";
import "./Styles/Header.css";
import logo from "../../Files/Images/tenacklogo.png";
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Logout } from "@mui/icons-material";
import { useAuth } from "../../Contexts/AuthContext";
import { sideBarData } from "./SideBarData";
import { useNavigate } from "react-router-dom";

function Header() {
  // import log out function
  const { logout } = useAuth();

  const navigate = useNavigate();

  // handle side bar open and close
  const [sidebar, setSidebar] = React.useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <div className="Header_body">
        <div>
          <img src={logo} alt="Tenack Logo" className="Header_logo" onClick={((r) => {navigate(`/dashboard`)})}/>
        </div>
        <div className="Header_right">
         

          <div className="HEader_right">
            <IconButton
              style={{ color: "rgb(18, 100, 100)" }}
              onClick={showSidebar}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </div>
      </div>

      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <div className="Header_nav_menu_content">
          <div>
            {sideBarData.map((item) => (
              <ListItem
                onClick={(e) => {
                  navigate(item.path);
                }}
                button
                key={Math.random()}
              >
                <ListItemIcon style={{ color: "white" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "large",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      {item.label}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </div>
          <div>
            <ListItem
              onClick={(e) => {
                logout();
              }}
              button
            >
              <ListItemIcon>
                <Logout style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "large",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                  >
                    logout
                  </Typography>
                }
              />
            </ListItem>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
