import React from 'react'
import Header from '../../Components/Header/Header'
import { useAuth } from '../../Contexts/AuthContext';
import { db } from '../../Firebase';
import PropertyPageContent from './Components/PropertyPageContent'
import "./Styles/PropertyPage.css"

function PropertyPage() {
    // hooks
    const {currentUser} = useAuth();
  
    // state varibles 
    const [state, setState] = React.useState({
      display_name: '', 
    })
  
    // fetching data
    React.useEffect(() => { // fetch user data
      db.collection('users')
      .doc(currentUser.uid)
      .get()
      .then((r) => {
        setState((state) => ({
          ...state,
          display_name: `${r.data().first_name} ${r.data().last_name}`
        }))
      })
  
    }, [])


  return (
    <>
    <Header />
    <div className="Dashboard_title_parent">
        <div className="Dashboard_title_sub">
          Welcome {state.display_name}
        </div>
      </div>
    <PropertyPageContent />
</>
  )
}

export default PropertyPage