import { TextField } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../Contexts/AuthContext'
import { db } from '../../../Firebase';
import "./Styles/RequestUpdateAccess.css"

function RequestUpdateAccess() {


    // hooks 
    const {currentUser} = useAuth();
    const {property_id, request_id} = useParams();


    // useEffect 

    React.useEffect(() => {

        db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .get()
    .then( async (r) => {

        const path = 
        await db.collection('properties')
        .doc(property_id)
        .get(); 
        const datData = path.data();

setState((state) => ({
        ...state, 
        access: r.data().property_notes ? r.data().property_notes : datData.property_notes ? datData.property_notes : ''
      }))
    }).catch((r) => {
      console.log('error', r)
    })
    }, [property_id, request_id, currentUser.uid])
    

    // state variables 
    const [state, setState] = React.useState({
        access: ''
    })

    // on blur save
    function onBlur () {
        db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .update({
        property_notes: state.access
    }).catch((r) => {
      console.log('error', r)
    })


    }



  return (<div className='RequestUpdateAccess'>
  <div className='title'> 
    What Is The Best Way To Access The Property?
  </div>
  <div>
    <TextField
    fullWidth
    multiline
    minRows={3}
    maxRows={8}
    value={state.access}
    onChange={((e) => {
        setState((state) => ({
            ...state, 
            access: e.target.value
        }))
    })}
    onBlur={onBlur}

    />
  </div>
  </div>
 
  )
}

export default RequestUpdateAccess