import { Card } from '@mui/material'
import React from 'react'
import "./Styles/PropertyAccessCard.css"
import PropTypes from 'prop-types';

function PropertyAccessCard(props) {

  const {access_notes} = props;
  return (<>
  <Card className='PropertyAccessCard_card'>
    <div className='PropertyAccessCard_title'> 
      Access Notes
    </div>
    <div className='PropertyAccessCard_text'> 
     {access_notes}
    </div> 
  </Card>
  </>
  )
}


PropertyAccessCard.propTypes = {
  access_notes: PropTypes.string,
};

export default PropertyAccessCard