import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./Pages/Login/Login";
import SignUpPage from "./Pages/Sign Up/SignUp";
import { AuthProvider, useAuth } from "./Contexts/AuthContext";
import Dashboard from "./Pages/Dashboard/Dashboard";
import PropertiesPage from "./Pages/Properties/PropertiesPage";
import PropertyPage from "./Pages/Property/PropertyPage";
import CreateRequestPage from "./Pages/New Maintenace Request/CreateRequestPage";
import EditRequestPage from "./Pages/New Maintenace Request/EditRequestPage";
import RequestTrackingPage from "./Pages/Tracking Page/RequestTrackingPage";

function App() {
  function PrivateRoute({ children }) {
    const auth = useAuth();

    if (auth.currentUser) {
      return children;
    } else {
      return <Navigate to="/login" />;
    }
  }

  return (
    <div className="app">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<LoginPage />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/:property_id"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/properties"
              element={
                <PrivateRoute>
                  <PropertiesPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/property/:property_id"
              element={
                <PrivateRoute>
                  <PropertyPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/property/:property_id/new_maintenance_request"
              element={
                <PrivateRoute>
                  <CreateRequestPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/property/:property_id/maintenance_request_edit/:request_id"
              element={
                <PrivateRoute>
                  <EditRequestPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/property/:property_id/request_tracking/:request_id"
              element={
                <PrivateRoute>
                  <RequestTrackingPage />
                </PrivateRoute>
              }
            />

            {/*  <Route path="/support_documents" element={<SupportPage />} />
           <Route path="/dashboard" element={<Dashboard />} /> */}
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
