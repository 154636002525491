import { Alert, Button, Card, LinearProgress, Snackbar } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import { useAuth } from "../../Contexts/AuthContext";
import { db } from "../../Firebase";
import LoadingPage from "./Components/LoadingPage";
import RequestConfirmDetails from "./Components/RequestConfirmDetails";
import RequestExtraDetails from "./Components/RequestExtraDetails";
import RequestImageUpload from "./Components/RequestImageUpload";
import RequestRelatedSpace from "./Components/RequestRelatedSpace";
import RequestTitle from "./Components/RequestTitle";
import RequestTradesmanType from "./Components/RequestTradesmanType";
import RequestUrgency from "./Components/RequestUrgency";
import StartRequestScreen from "./Components/StartRequestScreen";
import "./Styles/EditRequestPage.css";
import firebase from "firebase"
import RequestUpdateAccess from "./Components/RequestUpdateAccess";

function EditRequestPage() {
  const pageLimit = 8;
  // hooks
  const { property_id, request_id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate()

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    errorOpen: false,
    errorMessage: "",
    successMessage: "",
    successOpen: false,
    request: {},
    stepIndex: 0,
  });
  // fetch call
  React.useEffect(() => {
    db.collection("tenant")
      .doc(currentUser.uid)
      .collection("accessible_properties")
      .doc(property_id)
      .collection("maintenance_requests")
      .doc(request_id)
      .get()
      .then((r) => {
        const data = r.data();
        setState((state) => ({
          ...state,
          request: data,
        }));
      })
      .catch((r) => {
        console.log("error", r);
      });
  }, []);

  /* function to handle changing steps */
  function handleIndexChange(direction) {
    // accepts f & b for fowards and back

    // if direction is foward handle page increase
    if (direction === "f") {
      // check if index is a limit of X if so handle submit
      if (state.stepIndex === pageLimit) {
        // limit currently of 7

        if (
          window.confirm(
            `are you sure you're ready to submit your maintenace request?`
          )
        ) {
          setState((state) => ({
            ...state,
            stepIndex: state.stepIndex + 1,
          }));
          handleRequest()
        } else {
          return;
        }
      } else {
        setState((state) => ({
          ...state,
          stepIndex: state.stepIndex + 1,
        }));
      }
    }
    // else descrease page
    else {
      // check index is not already zero
      if (state.stepIndex === 0) {
        return;
      } else {
        setState((state) => ({
          ...state,
          stepIndex: state.stepIndex - 1,
        }));
      }
    }
  }

  // handle the submition of the complete form 
  function handleRequest () {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .collection('submission')
    .doc(request_id)
    .set({
      completed: true, 
      created_at: firebase.firestore.FieldValue.serverTimestamp()
    }).then((r) => {
      navigate(`/property/${property_id}`)
    }).catch((r) => {
      setState((state) => ({
        ...state, 
        stepIndex: state.stepIndex - 1,
        errorMessage: 'Failed to complete request...', 
        errorOpen: true
      }))
    })
  }

  // handle the progress calculation
  const progress = (100 / pageLimit ) * state.stepIndex;


  /* handle snackbar jazz */
  function handleErrorClose() {
    setState((state) => ({
      ...state,
      errorMessage: "",
      errorOpen: false,
      successOpen: false,
      successMessage: "",
    }));
  }
  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");



  return (
    <>
     <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{ maxWidth: "30%" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>


      <Header />
      <div className="EditRequestPage_parent">
        <div className="EditRequestPage_child">
          <Card className="EditRequestPage_card">
            <div className="EditRequestPage_progress">
              <LinearProgress
                value={progress}
                variant="determinate"
                color="inherit"
                style={{ height: "8px" }}
              />
            </div>

            <div>
              {state.stepIndex === 0 && <StartRequestScreen />}
              {state.stepIndex === 1 && <RequestTitle />}
              {state.stepIndex === 2 && <RequestTradesmanType />}
              {state.stepIndex === 3 && <RequestExtraDetails />}
              {state.stepIndex === 4 && <RequestUrgency />}
              {state.stepIndex === 5 && <RequestRelatedSpace />}
              {state.stepIndex === 6 && <RequestImageUpload />}
              {state.stepIndex === 7 && <RequestUpdateAccess />}
              {state.stepIndex === 8 && <RequestConfirmDetails />}
              {state.stepIndex === 9 && <LoadingPage />}

            </div>

            <div className="EditRequestPage_buttons">
              <Button
                onClick={(r) => {
                  handleIndexChange("b");
                }}
                style={{
                  backgroundColor: "lightgray",
                  color: "rgb(18, 100, 100)",
                  fontWeight: "600",
                }}
                disabled={state.stepIndex === 0 || state.stepIndex > pageLimit }
              >
                Back
              </Button>
              <Button
                onClick={(r) => {
                  handleIndexChange("f");
                }}
                style={{
                  backgroundColor: "rgb(18, 100, 100)",
                  color: "white",
                  fontWeight: "600",
                }}
                disabled={state.stepIndex > pageLimit}
              >
                {state.stepIndex === pageLimit ? "Submit" : "Next"}
                
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default EditRequestPage;
