import { Button, Card, Fab, TextField } from "@mui/material";
import React from "react";
import "./Styles/Login.css"
import logo from "../../Files/Images/tenacklogo.png"
import { Help } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {useAuth} from '../../Contexts/AuthContext'
import DualSnackBar from "../../Components/Snack Bar/DualSnackBar";

function LoginPage() {

    const { login} = useAuth()

    const navigate = useNavigate()


    const [state, setState] = React.useState({
        loading: false, 
        errorOpen: false, 
        errorMessage: '', 
        successOpen: false, 
        successMessage: '', 
        email: '', 
        password: ''
    })

function handleTextChange (field, e) {
    setState((state) => ({
        ...state,
        [field]: e.target.value
    }))
}



const [errorMessage, setErrorMessage] = React.useState('')

function handleUserLogin () { 
    login(state.email, state.password).then((r) => {
        navigate('/dashboard')
    }).catch((r) => {
        setErrorMessage(`${r.message}`)
    })

}

  return (
    <>
    <DualSnackBar errorMessage={errorMessage} successMessage={state.successMessage}/>
      <div className="LoginPage_master">
          
        <div className="LoginPage_sub">
          <Card className="LoginPage_card">
              <div className="LoginPage_logo_div"> 
              <img src={logo} alt='tenack business logo.' className="LoginPage_logo"/>
          </div>
          <div className="LoginPage_subtitle"> 
              (Tenant Login)
          </div>
              <div className="LoginPage_fullwidth"> 
              <TextField
              fullWidth
              label='Email Address'
              value={state.email}
              onChange={((e) => {
                  handleTextChange('email', e)
              })}
              />
              </div>
              <div className="LoginPage_fullwidth">
              <TextField
              fullWidth
              label='Password' 
              value={state.password}
              onChange={((e) => {
                  handleTextChange('password', e)
              })}
              type='password'
              onKeyUp={(e) => e.key === "Enter" && handleUserLogin(e)}
              />
               </div>

               <div className="LoginPage_button_div"> 
                   <Button style={{
                       color:'white', 
                       backgroundColor:'rgb(18, 100, 100)', 
                       width:'30%'
                   }}
                   onClick={(() => {
                    handleUserLogin()
                   })}
                   >
                       Login
                   </Button>
               </div>  
               <div className="LoginPage_link"> 
                <div className="LoginPage_link_text" onClick={((r) => {navigate(`/signup`)})}> create an account</div>
            </div>            
              </Card>
        </div>
      </div>

      <Fab style={{
          position:'fixed', 
          bottom:'20px', 
          right:'20px', 
          backgroundColor:'rgb(18, 100, 100)', 
          color: 'white'
      }}>
          <Help /> 

      </Fab>
    </>
  );
}

export default LoginPage;
