import { AddAPhoto, Close } from "@mui/icons-material";
import { Badge, CircularProgress, IconButton } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../Contexts/AuthContext";
import { db, storage } from "../../../Firebase";
import "./Styles/RequestImageUpload.css";

function RequestImageUpload() {
  // hooks
  const { currentUser } = useAuth();
  const { property_id, request_id } = useParams();

  // state variables
  const [state, setState] = React.useState({
    loading: true,
    files: [],
    urls: [],
    image_array: [],
    new_images: [],
    errorMessage: '',
    errorOpen: true,

  });

  // handle setting the default image links
  React.useEffect(() => {
    db.collection("tenant")
      .doc(currentUser.uid)
      .collection("accessible_properties")
      .doc(property_id)
      .collection("maintenance_requests")
      .doc(request_id)
      .get()
      .then((r) => {
        setState((state) => ({
          ...state,
          image_array: r.data().images ? r.data().images : [],
          loading: false,
        }));
      })
      .catch((r) => {
        console.log("error", r);
      });
  }, []);

  // handle sneaky hidden input ref click
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // handle image upload

  async function handleInput(e) {
    await setState((state) => ({
      ...state,
      loading: true,
    }));

    const data = await handleImageUpload(e);

    await setState((state) => ({
      ...state,
      image_array: state.image_array
        ? [].concat(state.image_array, data)
        : data,
    }));

    console.log(state);
    await db
      .collection("tenant")
      .doc(currentUser.uid)
      .collection("accessible_properties")
      .doc(property_id)
      .collection("maintenance_requests")
      .doc(request_id)
      .update({
        images: state.image_array ? [].concat(state.image_array, data) : data,
      })
      .then(() => console.log("successfully uploaded images..."))
      .catch(() => {
        setState((state) => ({
          ...state,
          errorMessage: 'Error',
          errorOpen: true,
        }));
        console.log("error");
      });

    setState((state) => ({
      ...state,
      loading: false,
    }));
  }

  async function handleImageUpload(e) {
    const arr = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      const imageName = `${newImage.name}_${Math.floor(
        Math.random() * (999 - 100 + 1) + 100
      )}`;
      await storage
        .ref(`${currentUser.uid}/request_images/${imageName}`)
        .put(newImage);
      const url = await storage
        .ref(`${currentUser.uid}/request_images/`)
        .child(imageName)
        .getDownloadURL();
      console.log({ title: imageName, url: url });
      await arr.push({ title: imageName, url: url });
      setState((state) => ({
        ...state,
        new_images: arr,
      }));
    }
    setState((state) => ({
      ...state,
      new_images: arr,
    }));
    console.log({ arr: arr, state: state.new_images });
    return arr;
  }


  // function handle image remove
  async function handleImageRemove(index) {

    if (window.confirm('Are you sure you want to remove this image?')) {
    const arr = [...state.image_array]
    arr.splice(index, 1)

      setState((state) => ({
          ...state, 
          loading: true,
      }))
      await db
      .collection("tenant")
      .doc(currentUser.uid)
      .collection("accessible_properties")
      .doc(property_id)
      .collection("maintenance_requests")
      .doc(request_id)
      .update({
        images:  arr,
      }).then((r) => {
          setState((state) => ({
              ...state, 
              loading: false, 
              image_array: arr
          }))
      }).catch((r) => {
          setState((state) => ({
              ...state, 
              errorMessage: 'Failed to remove image... please try again', 
              errorOpen: true
          }))
          console.log(r)
      })
    } else {
        return;
    }

  }

  return (
    <>
      <div className="RequestImageUpload_title">Upload photos</div>
      {state.loading && (
        <div className="RequestImageUpload_loading_parent">
          <div className="RequestImageUpload_loading_child">
            <CircularProgress size={50} color="inherit" />
          </div>
        </div>
      )}
      <div className="RequestImageUpload_img_list">
        {state.image_array && state.loading === false &&
          state.image_array.map((item, index) => (
            <div className="RequestImageUpload_img_parent" key={Math.random()}>
              <Badge className='RequestImageUpload_img_badge' badgeContent={<IconButton  onClick={((r) => {handleImageRemove(index)})} size="small" style={{color:'rgb(18, 100, 100)'}}> <Close /></IconButton>}>
                <img
                  src={item.url}
                  alt={item.title}
                  className="RequestImageUpload_img"
                />
              </Badge>
            </div>
          ))}
      </div>
      <div>
        <input
          accept="image/*"
          ref={hiddenFileInput}
          id="icon-button-file"
          type="file"
          multiple
          className={"dialog_box_select_file"}
          onChange={handleInput}
          style={{ display: "none" }}
        />

        <IconButton
          style={{ color: "rgb(18, 100, 100)" }}
          onClick={handleClick}
        >
          <AddAPhoto />
        </IconButton>
      </div>
    </>
  );
}

export default RequestImageUpload;
