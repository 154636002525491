import { Avatar, Card, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../Contexts/AuthContext';
import { db } from '../../../../Firebase';
import "./Styles/CurrentRequestsCard.css"

function CurrentRequestsCard() {

  // hooks
  const { property_id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate()

  // state
  const [state, setState] = React.useState({
    requests: [],
  })

  // fetch call 
  React.useEffect(() => {
    db.collection("tenant")
      .doc(currentUser.uid)
      .collection("accessible_properties")
      .doc(property_id)
      .collection("sent_requests")
      .where('complete', '!=', true)
      .get()
      .then( async (r) => {

        const docs = [...r.docs]

        const map = docs && docs.map( async (doc) => {
            const path = await 
            db.collection("tenant")
            .doc(currentUser.uid)
            .collection("accessible_properties")
            .doc(property_id)
            .collection("maintenance_requests")
            .doc(doc.id)
            .get();
            const data = path.data()
            return (
                {...data, ...doc.data(), id: doc.id}
            )
        })

        await Promise.all(map).then((result) => {
            setState((state) => ({
                ...state, 
                requests: result
            }));
          });

      })
      .catch((r) => {
        console.log("error", r);
      });
  }, []);

  return (<>
  <Card className='CurrentRequestsCard_card'> 
    <div className='CurrentRequestsCard_title'> 
      Current Maintenace Requests
    </div>
    <div className='CurrentRequestsCard_list'>


    {state.requests && state.requests.map((item) => (
<ListItem button dense key={item.id} onClick={((r) => {
  navigate(`/property/${property_id}/request_tracking/${item.id}`)
})}> 
      <ListItemAvatar> 
        <Avatar src={item.images && item.images[0] && item.images[0].url} alt={item.title}/>
      </ListItemAvatar>
      <ListItemText primary={item.title && item.title} secondary={<Typography style={{textTransform:'capitalize', fontSize:'small', color:'gray'}}>{item.status}</Typography> }/>


      </ListItem>


    ))}
    
      
    </div>
  </Card>
  </>
  )
}

export default CurrentRequestsCard