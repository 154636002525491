import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../Contexts/AuthContext';
import { db } from '../../../Firebase';
import './Styles/RequestTradesmanType.css'
import { tradesmanTypes } from './TradesmanTypes'

function RequestTradesmanType() {
  const { property_id, request_id } = useParams();
  const {currentUser} = useAuth();
  
  const [state, setState] = React.useState({
    tradesman_type: '', 
  })

  function onBlur () {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .update({
      tradesman_type: state.tradesman_type
    }).catch((r) => {
      console.log('error', r)
    })

  } 

  React.useEffect(() => {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .get()
    .then((r) => {
      setState((state) => ({
        ...state, 
        tradesman_type: r.data().tradesman_type ? r.data().tradesman_type : ''
      }))
    }).catch((r) => {
      console.log('error', r)
    })


  }, [])
  

  return (<>
  <div className='RequestTradesmanType_title'> 
      What type of tradesman is required?
  </div>
  <div className='RequestTradesmanType_select'> 
      <Select
      fullWidth
      style={{color:'rgb(18, 100, 100)'}}
      value={state.tradesman_type}
      onChange={((e) => {
        setState((state) => ({
          ...state, 
          tradesman_type: e.target.value
        }))
      })}
      onBlur={onBlur}
      >
    {tradesmanTypes && tradesmanTypes.map((item) => (
          <MenuItem key={item} value={item}>{item}</MenuItem>
    ))}
    </Select>
  </div>
  
  
  
  </>
  )
}

export default RequestTradesmanType