import React from 'react'
import "./Styles/TimelineItem.css"

function TimelineItem({data}) {

    if (data.date) {
        console.log()
        const server = data.date.toDate()
        const local = new Date(server).toLocaleDateString()
        const time = new Date(server).toLocaleString('en-US', { hour: 'numeric', minute:'numeric', hour12: true })


        if (data.booking_time === '') { 
 return (
 <div className="TimelineItem_item">
    <div className="TimelineItem_item_content">
            <div className='TimelineItem_time'> 
                {`${local} @ ${time}`}
            </div>
            <div className='TimelineItem_title'> 
            {data.title}
            </div>
            {data.icon}
    </div>
</div>
        )

        } else {
            const Bookingserver = data.booking_time
        const Bookinglocal = new Date(Bookingserver).toLocaleDateString()


            return (
                <div className="TimelineItem_item">
                   <div className="TimelineItem_item_content">
                           <div className='TimelineItem_time'> 
                               {`${local} @ ${time}`}
                           </div>
                           <div className='TimelineItem_title'> 
                           {data.title}
                           </div>
                           <div className='TimelineItem_subtitle'> 
                           {Bookinglocal}
                           </div>
                           {data.icon}
                   </div>
               </div>
                       )



        }
       

    } else {
        return (
<div className="TimelineItem_item-incomplete">
<div className="TimelineItem_item_content-incomplete">
        
        <div className='TimelineItem_title-incomplete'> 
        {data.title} 
        </div>
        {data.icon}
</div>
</div>
        )

    }
}

export default TimelineItem