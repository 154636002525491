import { House, PushPin } from "@mui/icons-material";
import {
  Alert,
  Card,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import { useAuth } from "../../Contexts/AuthContext";
import { db } from "../../Firebase";
import "./Styles/PropertiesPage.css";

function PropertiesPage() {
  /* state variables */
  const { currentUser } = useAuth();
  const navigate = useNavigate()
  const [state, setState] = React.useState({
    properties: [],
    holdingProperties: [],
    default_property: "",
    errorOpen: false,
    errorMessage: "",
    successMessage: "",
    successOpen: false,
    loading: false
  });

  /* fetch calls // useEffect */


  React.useEffect(() => {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .get()
    .then( async (r) => {
        const docs = r.docs

        const map = docs && docs.map( async (doc) => {
            const path = await db.collection('properties')
            .doc(doc.id)
            .get();
            const data = path.data()
            return (
                {...data, id: doc.id}
            )
        })

        await Promise.all(map).then((result) => {
            setState((state) => ({
                ...state, 
                properties: result
            }));
          });


    })

  }, [])
  

  React.useEffect(() => {
    db.collection("tenant")
      .doc(currentUser.uid)
      .collection("private_data")
      .doc("preferences")
      .get()
      .then( async (r) => {
        setState((state) => ({
          ...state,
          default_property: r.data().default_property,
        }));
      });
  }, []);

  /* handle changing pinned property */

  function handlePinUpdate(id) {
    if (state.default_property === id) {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "This Property is already set to default...",
        successOpen: false,
        successMessage: "",
      }));
    } else {
      db.collection("tenant")
        .doc(currentUser.uid)
        .collection("private_data")
        .doc("preferences")
        .set({
          default_property: id,
        })
        .then((r) => {
          setState((state) => ({
            ...state,
            errorOpen: false,
            errorMessage: "",
            successOpen: true,
            successMessage: "Successfully updated default property...",
            default_property: id,
          }));
        })
        .catch((e) => {
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to update default property...",
            successOpen: false,
            successMessage: "",
          }));
        });
    }
  }

  /* handle snackbar jazz */
  function handleErrorClose() {
    setState((state) => ({
      ...state,
      errorMessage: "",
      errorOpen: false,
      successOpen: false,
      successMessage: "",
    }));
  }
  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{ maxWidth: "30%" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{ maxWidth: "30%" }}
      >
        <Alert onClose={handleErrorClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Header />
      <div className="PropertiesPage_parent_div">
        <div className="PropertiesPage_child_div">
          <Card className="PropertiesPage_card">
            <div className="PropertiesPage_title">Accessible Properties</div>
            <div className="PropertiesPage_list">
              {state.properties &&
                state.properties.map((property) => (
                  <ListItem key={property.id}
                  button
                  onClick={((r) => {
                      navigate(`/property/${property.id}`)
                  })}
                  
                  >
                    <ListItemIcon>
                      <House />
                    </ListItemIcon>
                    <ListItemText primary={`
                    ${property.address_line_1 && property.address_line_1} ${property.address_city && property.address_city}`} />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={(r) => {
                          handlePinUpdate(property.id);
                        }}
                      >
                        <PushPin style={state.default_property === property.id ? { color: "rgb(18, 100, 100)" } : {}} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default PropertiesPage;
