import { Card, CircularProgress } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import { useAuth } from "../../Contexts/AuthContext";
import { db } from "../../Firebase";
import PropertyPageContent from "../Property/Components/PropertyPageContent";
import "./Styles/Dashboard.css";

function Dashboard() {

  // hooks
  const {currentUser} = useAuth();

  const navigate = useNavigate()

  // state varibles 
  const [state, setState] = React.useState({
    loading: true, 
    display_name: '', 
    pinned_property: ''
  })

  // fetching data
  React.useEffect(() => { // fetch user data
    db.collection('users')
    .doc(currentUser.uid)
    .get()
    .then((r) => {
      setState((state) => ({
        ...state,
        display_name: `${r.data().first_name} ${r.data().last_name}`, 
      }))
    })

  }, [])

  React.useEffect(() => { // fetch pinned property data
    db.collection('tenant')
    .doc(currentUser.uid)
    .collection('private_data')
    .doc('preferences')
    .get()
    .then((r) => {
      if (r.exists) {
         setState((state) => ({
        ...state,
        pinned_property: r.data().default_property ? r.data().default_property : '', 
        loading: false,
      }))
      navigate(`/dashboard/${r.data().default_property}`)
      } else {
        db.collection('tenant')
        .doc(currentUser.uid)
        .collection('accessible_properties')
        .get()
        .then((r) => {
          if(r.docs.length === 0) {
            return;
          } else {
            db.collection('tenant')
    .doc(currentUser.uid)
    .collection('private_data')
    .doc('preferences')
    .set({
      default_property: r.docs[0].id
    })
    setState((state) => ({
      ...state, 
      pinned_property: r.docs[0].id, 
      loading: false

    }))
            
          }
        })

        return;
      }
    })

  }, [])
  return (
    <>
      <Header />

      <div className="Dashboard_title_parent">
        <div className="Dashboard_title_sub">
          Welcome {state.display_name}
        </div>
      </div> 

      {state.loading && 
      <>
      <div style={{width:'100vw', height:'90vh', display: 'flex', flexDirection: 'row', justifyContent:'center'}}> 
        <div style={{ height:'100%', display: 'flex', flexDirection: 'column', justifyContent:'center'}}> 
          <div> 
            <CircularProgress size={50} style={{color:'rgb(18, 100, 100)'}}/>
          </div>
        </div>
      </div>
      </>
      }

      {state.pinned_property === '' && state.loading === false ? (
        <>
         <div className="Dashboard_placeholder_parent">
        <div className="Dashboard_placeholder_child">

            <Card className="Dashboard_placeholder_card">
              <div className="Dashboard_placeholder_text_Opps"> Oops... </div>
              <div className="Dashboard_placeholder_text_Subtitle">
                Looks like you currently don't have a pinned property.
              </div>

              <div className="Dashboard_placeholder_text_Text_parent">
                <div className="Dashboard_placeholder_text_Text">
                  Naivgate to the properties page to set a default property. the property will then appear on this page 
                </div>
              </div>
            </Card>
        </div>
      </div>
        </>
      )
      : 
      (
        <>
        {state.pinned_property !== '' && state.loading === false ? <>
       <PropertyPageContent />  
        </>
        :
        <>
        </>
        }

        </>
      )
    }
    </>
  );
}

export default Dashboard;
