import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../Contexts/AuthContext';
import { db } from '../../../Firebase';
import './Styles/RequestRelatedSpace.css'

function RequestRelatedSpace() {
  // hooks
  const { property_id, request_id } = useParams();
  const {currentUser} = useAuth();
  
  // states
  const [state, setState] = React.useState({
    spaces: [],
    selected_space: '' 
  })

  // fetch the properties spaces
  React.useEffect(() => {
    db.collection('properties')
    .doc(property_id)
    .collection('spaces')
    .get()
    .then((r) => {
      setState((state) => ({
        ...state, 
        spaces: r.docs.map((doc) => ({...doc.data(), id: doc.id}))
      }))
    }).catch((r) => {
      console.log('error', r)
    })

  }, [])

  function onBlur () {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .update({
      selected_space: state.selected_space
    }).catch((r) => {
      console.log('error', r)
    })
  } 

  React.useEffect(() => {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .get()
    .then((r) => {
      setState((state) => ({
        ...state, 
        selected_space: r.data().selected_space ? r.data().selected_space : ''
      }))
    }).catch((r) => {
      console.log('error', r)
    })


  }, [])
  

  return (<>
  <div className='RequestRelatedSpace_title'> 
      Is This issue in a particular space of the house?
  </div>
  <div className='RequestRelatedSpace_subtitle'> 
  if the request relates to the whole property for example no hot water, then you can leave this section blank

  </div>
  <div className='RequestRelatedSpace_select'> 
      <Select
      fullWidth
      style={{color:'rgb(18, 100, 100)'}}
      value={state.selected_space}
      onChange={((e) => {
        setState((state) => ({
          ...state, 
          selected_space: e.target.value,
        }))
      })}
      onBlur={onBlur}
      >
    {state.spaces && state.spaces.map((item) => (
          <MenuItem  key={item.id} value={item.id}>{item.display_name}</MenuItem>
    ))}
    </Select>
  </div>
  
  
  
  </>
  )
}

export default RequestRelatedSpace