import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../Firebase.js";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  async function signup(email, password, first_name, last_name, user_type) {
    await auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (cred) => {
        await addUtilitiesData(
          user_type,
          cred.user.uid,
          email,
          first_name,
          last_name
        );
      });
    console.log(user_type);
  }

  function addUtilitiesData(user_type, userId, email, first_name, last_name) {
    console.log(user_type, userId);
    db.collection("users")
      .doc(userId)
      .set({
        user_type: user_type,
        registered: false,
        userUID: userId,
        email: email,
        first_name: first_name,
        last_name: last_name,
      }).then((r) => 
      console.log(r))
  }

   function login(email, password) {
    return  auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
