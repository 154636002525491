import { Card } from '@mui/material'
import React from 'react'
import "./Styles/PropertyAddressCard.css"
import PropTypes from 'prop-types';


function PropertyAddressCard(props) {
  const {
    line_1, 
    line_2, 
    city, 
    state, 
    postcode, 
    country
  } = props.address
  return (<>
  <Card className='PropertyAddressCard_card'> 
  <div className='PropertyAddressCard_title'> 
    Address
  </div>
  <div className='PropertyAddressCard_line_top'>{`${line_1} ${line_2}`}</div>
  <div className='PropertyAddressCard_line'>{`${city}`}</div>
<div className='PropertyAddressCard_line'>{`${state}`}</div>
<div className='PropertyAddressCard_line'>{`${postcode}`}</div>
<div className='PropertyAddressCard_line'>{`${country}`}</div>

  </Card>
  </>
  )
}


PropertyAddressCard.propTypes = {
  address: PropTypes.object,
};


export default PropertyAddressCard