import { Button, Card, Fab, TextField } from "@mui/material";
import React from "react";
import "./Styles/SignUp.css";
import logo from "../../Files/Images/tenacklogo.png";
import { Help } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../Firebase";
import firebase from "firebase";
import DualSnackBar from "../../Components/Snack Bar/DualSnackBar";

function SignUpPage() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    loading: false,
    errorOpen: false,
    errorMessage: "",
    successOpen: false,
    successMessage: "",
    email: "",
    password: "",
    passwordConfirm: "",
    first_name: "",
    last_name: "",
  });

  function handleTextChange(field, e) {
    setState((state) => ({
      ...state,
      [field]: e.target.value,
    }));
  }

  async function handleUserSignUp() {
    if (state.password === state.passwordConfirm) {
      // passwords match process account create

      await auth
        .createUserWithEmailAndPassword(state.email, state.password)
        .then((r) => {
          // successfully created user
         // console.log(r.user.uid)

          db.collection("users")
            .doc(r.user.uid)
            .set({
              user_type: "tenant",
              first_name: state.first_name,
              last_name: state.last_name,
              created_at: firebase.firestore.FieldValue.serverTimestamp(),
              uid: r.user.uid,
            })
            .then((r) => {
              setState((state) => ({
                ...state,
                errorMessage: ``,
                errorOpen: false,
                successOpen: true,
                successMessage:
                  "Successfully created account you will be redirected shortly...",
              }));
              setTimeout(() => {
                navigate(`/dashboard`)
              }, 1000);


              setTimeout(
                function () {
                  //Start the timer
                  setState((state) => ({
                    ...state,
                    success_message: "",
                  })); //After 1 second, set render to true
                }.bind(),
                10000
              );
            })
            .catch((e) => {
              setState((state) => ({
                ...state,
                errorMessage: `An Error Occured while making your account... please contact support.`,
                errorOpen: true,
                successOpen: false,
                successMessage: "",
              }));
            });

          console.log(r);
        })
        .catch((r) => {
          // error occured
          setState((state) => ({
            ...state,
            errorMessage: `${r.message}`,
            errorOpen: true,
            successOpen: false,
            successMessage: "",
          }));
          console.log(r);
        });
    } else {
      // set error
      setState((state) => ({
        ...state,
        errorMessage: `Passwords do not match...`,
        errorOpen: true,
        successOpen: false,
        successMessage: "",
      }));
    }
  }
  return (
    <>
    <DualSnackBar errorMessage={state.errorMessage} successMessage={state.successMessage} />
 

      <div className="LoginPage_master">
        <div className="LoginPage_sub">
          <Card className="LoginPage_card">
            <div className="LoginPage_logo_div">
              <img
                src={logo}
                alt="tenack business logo."
                className="LoginPage_logo"
              />
            </div>
            <div className="LoginPage_subtitle">(Tenant Login)</div>
            <div className="SignUpPage_half_fields">
              <div className="SignUpPage_half">
                <TextField
                  fullWidth
                  label="First Name"
                  value={state.first_name}
                  onChange={(e) => {
                    handleTextChange("first_name", e);
                  }}
                />
              </div>
              <div className="SignUpPage_half">
                <TextField
                  fullWidth
                  label="Last Name"
                  value={state.last_name}
                  onChange={(e) => {
                    handleTextChange("last_name", e);
                  }}
                />
              </div>
            </div>

            <div className="LoginPage_fullwidth">
              <TextField
                fullWidth
                label="Email Address"
                value={state.email}
                onChange={(e) => {
                  handleTextChange("email", e);
                }}
              />
            </div>
            <div className="LoginPage_fullwidth">
              <TextField
                fullWidth
                label="Password"
                value={state.password}
                onChange={(e) => {
                  handleTextChange("password", e);
                }}
                type="password"
              />
            </div>
            <div className="LoginPage_fullwidth">
              <TextField
                fullWidth
                label="Confirm Password"
                value={state.passwordConfirm}
                onChange={(e) => {
                  handleTextChange("passwordConfirm", e);
                }}
                type="password"
              />
            </div>

            <div className="LoginPage_button_div">
              <Button
                style={{
                  color: "white",
                  backgroundColor: "rgb(18, 100, 100)",
                  width: "30%",
                }}
                onClick={(e) => {
                  handleUserSignUp();
                }}
              >
                Create Account
              </Button>
            </div>
            <div className="LoginPage_link">
              <div
                className="LoginPage_link_text"
                onClick={(r) => {
                  navigate(`/`);
                }}
              >
                {" "}
                Already have an account? Login
              </div>
            </div>
          </Card>
        </div>
      </div>

      <Fab
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "rgb(18, 100, 100)",
          color: "white",
        }}
      >
        <Help />
      </Fab>
    </>
  );
}

export default SignUpPage;
