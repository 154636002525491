import { ArrowBackIos } from "@mui/icons-material";
import { Card, IconButton } from "@mui/material";
import React from "react";
import "./Styles/MultiImageDisplay.css";

function MultiImageDisplay({arr}) {

  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    if (arr) {
      setImages(arr);
    } else {
      return;
    }
  }, [arr]);

  // state variables
  const [state, setState] = React.useState({
    index: 0,
  });

  // handle image back
  function handleImageDecrease() {
    if (state.index === 0) {
      return;
    } else {
      setState((state) => ({
        ...state,
        index: state.index - 1,
      }));
    }
  }

  // handle image foward

  function handleImageIncrease() {
    if (state.index + 1 === images.length) {
      return;
    } else {
      setState((state) => ({
        ...state,
        index: state.index + 1,
      }));
    }
  }

  return (
    <>
      <Card className="MultiImageDisplay_card">
        <div className="MultiImageDisplay_navigation">
          <div className="MultiImageDisplay_icon">
            <IconButton
              onClick={(r) => {
                handleImageDecrease();
              }}
              disabled={state.index === 0 ? true : false}
            >
              <ArrowBackIos />
            </IconButton>
          </div>
        </div>
        <div className="MultiImageDisplay_image_div">
          <div className="MultiImageDisplay_image_column">
            <img
              src={images && images[state.index] && images[state.index].url}
              alt={""}
              className="MultiImageDisplay_img"
            />
          </div>
        </div>
        <div className="MultiImageDisplay_navigation">
          <div className="MultiImageDisplay_icon_right">
            <IconButton
              onClick={(r) => {
                handleImageIncrease();
              }}
              disabled={state.index + 1 === images.length}
            >
              <ArrowBackIos />
            </IconButton>
          </div>
        </div>
      </Card>
    </>
  );
}

export default MultiImageDisplay;
