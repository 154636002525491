import React from "react";
import { CheckCircle } from "@mui/icons-material";

export const progressEvents = [
  {
    title: "Request Succesfully Created",
    code: "#1",
    icon: <CheckCircle color="inherit" className="circle" />,
    complete: true,
    booking_time: ''
  },
  {
    title: "Request Assigned To Tradesman",
    code: "#3",
    icon: <CheckCircle color="inherit" className="circle" />,
    complete: false,
    booking_time: ''
  },
  {
    title: "Appointment Created",
    code: "#5",
    icon: <CheckCircle color="inherit" className="circle" />,
    complete: false,
    booking_time: ''
  },
  {
    title: "Request Completed",
    code: "#8",
    icon: <CheckCircle color="inherit" className="circle" />,
    complete: false,
    booking_time: ''
  },
];
