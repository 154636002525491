import { TextField } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../Contexts/AuthContext";
import { db } from "../../../Firebase";
import "./Styles/RequestExtraDetails.css";

function RequestExtraDetails() {
  const { property_id, request_id } = useParams();
  const {currentUser} = useAuth();
  
  const [state, setState] = React.useState({
    message: '', 
  })

  function onBlur () {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .update({
      message: state.message
    }).catch((r) => {
      console.log('error', r)
    })

  } 

  React.useEffect(() => {
    db.collection("tenant")
    .doc(currentUser.uid)
    .collection("accessible_properties")
    .doc(property_id)
    .collection("maintenance_requests")
    .doc(request_id)
    .get()
    .then((r) => {
      setState((state) => ({
        ...state, 
        message: r.data().message ? r.data().message : ''
      }))
    }).catch((r) => {
      console.log('error', r)
    })


  }, [])


  return (
    <>
      <div className="RequestExtraDetails_title">
        In as much detail as possible what is the issue?
      </div>
      <div className="RequestExtraDetails_textfield">
        <TextField
          placeholder="Tap in main bathroom is leaking from the under side of sink we placed a bucket underneath but by the end of the day it's full..."
          fullWidth
          style={{ color: "rgb(18, 100, 100)" }}
          multiline
          rows={8}
          value={state.message}
          onChange={((e) => {
            setState((state) => ({
              ...state, 
              message: e.target.value
            }))
          })}
          onBlur={onBlur}

        />
      </div>
    </>
  );
}

export default RequestExtraDetails;
