import { Build } from "@mui/icons-material";
import { Fab } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useWindowDimensions from "../../../Hooks/UseWindowDimentions";
import CurrentRequestsCard from "./Cards/CurrentRequestsCard";
import PastRequestsCard from "./Cards/PastRequestsCard";
import PropertyAccessCard from "./Cards/PropertyAccessCard";
import PropertyAddressCard from "./Cards/PropertyAddressCard";
import PropertyFloorPlanCard from "./Cards/PropertyFloorPlanCard";
import PropertyImageCard from "./Cards/PropertyImageCard";
import "./Styles/PropertyPageContent_large.css";
import "./Styles/PropertyPageContent_small.css";
import { db } from "../../../Firebase";

function PropertyPageContent() {
  // hooks
  const { property_id } = useParams();
  const navigate = useNavigate()

  //state

  const [state, setState] = React.useState({
    property: {},
  });

  React.useEffect(() => {
    db.collection("properties")
      .doc(property_id)
      .get()
      .then((r) => {
        if (r.data()) {
          setState((state) => ({
            ...state,
            property: r.data(),
          }));
        } else {
          return;
        }
      });
  }, []);

  const window = useWindowDimensions();

  const addressObj = {
    line_1:
      state.property && state.property.address_line_1
        ? state.property.address_line_1
        : "",
    line_2:
      state.property && state.property.address_line_2
        ? state.property.address_line_2
        : "",
    city:
      state.property && state.property.address_city
        ? state.property.address_city
        : "",
    state:
      state.property && state.property.address_state
        ? state.property.address_state
        : "",
    postcode:
      state.property && state.property.address_postcode
        ? state.property.address_postcode
        : "",
    country:
      state.property && state.property.address_country
        ? state.property.address_country
        : "",
  };

  if (window.width < 800) {
    // smaller screens - single colmn
    return (
      <div className="PropertyPageContent_small">
                      <PropertyAddressCard address={addressObj}/>

       <PropertyImageCard
                url={
                  state.property.display_image && state.property.display_image
                }
              />
              <PropertyAccessCard
                access_notes={
                  state.property.property_notes && state.property.property_notes
                }
              />
              <PropertyFloorPlanCard
                url={state.property.floor_plan && state.property.floor_plan}
              />
              <CurrentRequestsCard />
              <PastRequestsCard />
              <div className="PropertyPage_small_fab">
          <Fab style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }} onClick={((e) => {
            navigate(`/property/${property_id}/new_maintenance_request`)
          }) }>
            <Build />
          </Fab>
        </div>
      </div>
      
    );
  } else {
    // bigger screens - dual colomn
    return (
      <>
        <div className="PropertyPage_large_master">
          <div className="PropertyPage_large_body">
            <div className="PropertyPage_large_col">
              {/* left */}
              
              <PropertyImageCard
                url={
                  state.property.display_image && state.property.display_image
                }
              />
              <PropertyAccessCard
                access_notes={
                  state.property.property_notes && state.property.property_notes
                }
              />
              <PropertyFloorPlanCard
                url={state.property.floor_plan && state.property.floor_plan}
              />
            </div>
            <div className="PropertyPage_large_col">
              {/* right */}
              <CurrentRequestsCard />
              <PastRequestsCard />
            </div>
          </div>
        </div>

        <div className="PropertyPage_large_fab">
          <Fab style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }} onClick={((e) => {
            navigate(`/property/${property_id}/new_maintenance_request`)
          }) }>
            <Build />
          </Fab>
        </div>
      </>
    );
  }
}

export default PropertyPageContent;
