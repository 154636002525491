import React from "react";
import "./Styles/RequestTrackingPage.css";
import Header from "../../Components/Header/Header";
import { Button, Card, Dialog, LinearProgress } from "@mui/material";
import MultiImageDisplay from "../../Components/Multiple Image Display/MultiImageDisplay";
import TimelineItem from "./TimelineItem";
import "./Styles/TimelineItem.css";
import { progressEvents } from "./RequestEvents";
import { useAuth } from "../../Contexts/AuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../Firebase";

function RequestTrackingPage() {
  // hooks
  const { currentUser } = useAuth();
  const { property_id, request_id } = useParams();

  // state variables
  const [open, setOpen] = React.useState(false);
  const [request, setRequest] = React.useState({});
  const requestItems = [...progressEvents];
  const [requests, setRequests] = React.useState([]);
  const [progress, setProgress] = React.useState(0);

  // handle dialog open & close
  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  // useffect fetch call
  React.useEffect(() => {
    db.collection("tenant")
      .doc(currentUser.uid)
      .collection("accessible_properties")
      .doc(property_id)
      .collection("maintenance_requests")
      .doc(request_id)
      .get()
      .then((r) => {
        setRequest(r.data());
        setProgress(25);
      })
      .catch((r) => {
        console.log("error", r);
      });
  }, []);

  React.useEffect(() => {
    db.collection("job_requests")
      .doc(request_id)
      .get()
      .then(async (r) => {
        if (r.exists) {
    requestItems.splice(0, 1, {
          ...requestItems[0],
          date: r.data().created_at ? r.data().created_at : '',
        });
        requestItems.splice(1, 1, {
          ...requestItems[1],
          date: r.data().approved_at ? r.data().approved_at : "",
        });
        requestItems.splice(2, 1, {
          ...requestItems[2],

          date: r.data().assigned_date ? r.data().assigned_date : "",
          booking_time: r.data().booking_time ? r.data().booking_time : "",
        });
        requestItems.splice(3, 1, {
          ...requestItems[3],
          date: r.data().completed_at ? r.data().completed_at : "",
        });

        const index = requestItems.findIndex((item) => item.date === "");
        if (index === -1) {
          setProgress(100);
        } else {

      
          setProgress((100 / 4) * index + 1);
        }
        setRequests(requestItems);
        } else {
            // fetch the document in the private collection 
            const path =   
            await db.collection("tenant")
            .doc(currentUser.uid)
            .collection("accessible_properties")
            .doc(property_id)
            .collection("maintenance_requests")
            .doc(request_id)
            .get()
            const requestData = path.data()

            requestItems.splice(0, 1, {
              ...requestItems[0],
              date: requestData.created_at ? requestData.created_at : '',
            });

            const index = requestItems.findIndex((item) => item.date === "");

            if (index === -1) {
              setProgress(100);
            } else {
    
              setProgress((100 / 4) * index + 1);
            }
            setRequests(requestItems);
            
  

            

        }
    
      })
      .catch((r) => {
        console.log("error", r);
      });
  }, []);

  function DialogWindow() {
    return (
      <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
        <div className="RequestTrackingPage_dialog">
          <div className="RequestTrackingPage_title"> Track Progress</div>
          <div className="Timeline_list">
            {requests &&
              requests.map((data, idx) => (
                <TimelineItem data={data} key={idx} />
              ))}
          </div>
          <div className="RequestTrackingPage_button_div">
            <Button
              href="mailto:support@tenack.com?subject=Issue While Tracking Maintenace Request"
              color="inherit"
            >
              Report Issue
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
  return (
    <>
      <DialogWindow />
      <Header />
      <div className="RequestTrackingPage_parent">
        <div className="RequestTrackingPage_child">
          <Card className="RequestTrackingPage_card">
            <div className="RequestTrackingPage_card_progress">
              <LinearProgress
                color="inherit"
                value={progress}
                variant="determinate"
              />
            </div>
            <div className="RequestTrackingPage_card_button_bar">
              <Button
                style={{ color: "rgb(18, 100, 100)" }}
                onClick={handleDialogOpen}
              >
                Track Progress
              </Button>
            </div>
            <div className="RequestTrackingPage_img_card_div">
              <div className="RequestTrackingPage_img_card">
                <MultiImageDisplay arr={request.images} />
              </div>
            </div>
            <Card className="RequestTrackingPage_sub_card">
              <div className="RequestTrackingPage_subtitle">
                {request.title}
              </div>
              <div className="RequestTrackingPage_message">
                {request.message}
              </div>
            </Card>
          </Card>
        </div>
      </div>
    </>
  );
}

export default RequestTrackingPage;
