import { CircularProgress } from '@mui/material'
import React from 'react'
import "./Styles/LoadingPage.css"

function LoadingPage() {
  return (<>
   <div className='LoadingPage_parent'>
       <div className='LoadingPage_child'> 
           <CircularProgress color='inherit' size={50}/>
       </div>


   </div>
  </>
   
  )
}

export default LoadingPage