import React from "react";
import "./Styles/RequestConfirmDetails.css";
import { TextField } from "@mui/material";
import { db } from "../../../Firebase";
import { useAuth } from "../../../Contexts/AuthContext";
import { useParams } from "react-router-dom";

function RequestConfirmDetails() {

  // hooks 
  const { currentUser } = useAuth();
  const { property_id, request_id } = useParams();


  // state variables
  const [state, setState] = React.useState({
    request: {},
    loading: true,
    title: '',
    tradesman_type: '', 
    message: '', 
    urgency: '', 
    space: '', 
    images: []

  });

  // handle setting the default image links
  React.useEffect(() => {
    db.collection("tenant")
      .doc(currentUser.uid)
      .collection("accessible_properties")
      .doc(property_id)
      .collection("maintenance_requests")
      .doc(request_id)
      .get()
      .then((r) => {
        setState((state) => ({
          ...state,
          request: r.data(),
          title: r.data().title ? r.data().title : '' , 
          tradesman_type: r.data().tradesman_type ? r.data().tradesman_type : '' ,
          message: r.data().message ? r.data().message : '' , 
          urgency: r.data().urgency ? r.data().urgency : '' , 
          space: r.data().space ? r.data().space : '' , 
          images: r.data().images ? r.data().images : [] , 
          loading: false,
        }));
      })
      .catch((r) => {
        console.log("error", r);
      });
  }, []);

  return (
    <div className="RequestConfirmDetails_body">
      <div className="RequestConfirmDetails_title">Confirm Details</div>
      <div>
        <div className="RequestConfirmDetails_textField">
          <TextField fullWidth label="Title" disabled 
          value={state.title}/>
        </div>
        <div className="RequestConfirmDetails_textField">
          <TextField fullWidth label="Tradesman" disabled 
          value={state.tradesman_type}/>
        </div>
        <div className="RequestConfirmDetails_textField">
          <TextField
            fullWidth
            label="Description"
            disabled
            rows={8}
            multiline
          
          value={state.message}/>
        </div>
        <div className="RequestConfirmDetails_textField">
          <TextField fullWidth label="Urgency" disabled 
          value={state.urgency}/>
        </div>
        <div className="RequestConfirmDetails_textField">
          <TextField fullWidth label="Space" disabled 
          value={state.space}/>
        </div>
      </div>
      <div className="RequestConfirmDetails_images">
        {state.images && state.images.map((image) => (
          <div key={Math.random()}   className="RequestConfirmDetails_img_div">
            <img src={image.url} alt={image.title}   className="RequestConfirmDetails_img"/> 
          </div>
        ))}
      </div>
    </div>
  );
}

export default RequestConfirmDetails;
